<template>
  <div>
    <b-card>
      <!-- Filter Form -->
      <div class="d-flex align-items-start filter-form">
        <div>
          <b-form
            class="mb-2"
            inline
          >

            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.name"
              placeholder="User Name"
            ></b-form-input>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.role"
              :options="roleType"
              value-field="value"
              text-field="label"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Role</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              value-field="value"
              text-field="label"
              v-model="filter.status"
              :options="statusList"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Status</b-form-select-option>
              </template>
            </b-form-select>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="search()"
            >Search</b-button>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="resetFilter()"
            >Reset</b-button>
          </b-form>
        </div>

        <b-button
          pill
          class="ml-auto"
          variant="outline-success"
          @click="addmodal()"
        >
          <b-icon
            icon="person-plus"
            class="mr-1"
          ></b-icon>
          Add User
        </b-button>

      </div>

      <hr />

      <!-- Data Table -->
      <div
        class="d-block text-center"
        v-if="!tabledata.length && !loading"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Record Found</h5>
      </div>
      <b-table
        :items="tabledata"
        :fields="fields"
        id="tabledata"
        :busy="loading"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(role)="data">
          {{ data.value | role }}
        </template>
        <template #cell(active)="data">
          <b-form-checkbox
            v-model="data.item.status"
            name="check-button"
            switch
            @change="userStatusChange(data.item)"
          >
          </b-form-checkbox>
        </template>

        <template #cell(add_time)="data">
          {{ data.value | dateFormat}}
        </template>

        <template #cell(action)="data">
          <div style="width: 10px">
            <b-dropdown
              variant="outline-white"
              no-caret
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical"></b-icon>
              </template>
              <b-dropdown-item @click="editmodal(data.item)">
                <b-icon icon="pencil-square"></b-icon>
                Edit
              </b-dropdown-item>
            </b-dropdown>
            <!-- <b-button size="sm" variant="outline-secondary" @click="editmodal(data.item)">
              <b-icon icon="pencil-square" class="mr-1"></b-icon>
              <small>Edit</small>
            </b-button> -->
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="total > 50 && !loading"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        pills
        @input="handlePageClick"
      ></b-pagination>
      <!-- addUser Modal  -->
      <b-modal
        title="Add User"
        ref="addModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="addchannelrules">
          <b-form>

            <b-form-group label="User Name">
              <validation-provider
                #default="{ errors }"
                name="User Name"
                :rules="{required: true, regex: /^[a-zA-Z0-9\s]*$/ }"
              >
                <b-form-input
                  v-model="addUser.name"
                  placeholder="Enter User Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                :rules="{ required: true, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ }"
              >
                <b-form-input
                  v-model="addUser.email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Email Id"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  v-model="addUser.pass"
                  placeholder="Enter Password"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Role">
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  value-field="value"
                  text-field="label"
                  v-model="addUser.role"
                  :options="roleType"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Role</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Select permissions below">
              <div
                v-for="option in permissions"
                :key="option.value"
              >
                <b-form-checkbox
                  :value="option.value"
                  v-model="addUser.permissions.menu"
                >
                  <strong>{{ option.text }}</strong>
                </b-form-checkbox>

                <b-form-checkbox-group
                  v-model="addUser.permissions.submenu"
                  :disabled="!addUser.permissions.menu.includes(option.value)"
                >
                  <b-form-checkbox
                    v-for="subOption in option.sub"
                    :key="subOption.value"
                    :value="subOption.value"
                  >
                    {{ subOption.text }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <hr />

              </div>
            </b-form-group>

            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelAdd"
              >Cancel</b-button>
              <b-button
                pill
                class="mr-2"
                variant="primary"
                @click="resetUser"
              >Reset</b-button>
              <b-overlay
                :show="loadbtn"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="!loadbtn"
              >
                <b-button
                  pill
                  type="submit"
                  variant="primary"
                  @click.prevent="adduser"
                >Add</b-button>
              </b-overlay>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- editUser Modal  -->
      <b-modal
        title="Edit User"
        ref="editModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <b-form-group label="User Name">
              <validation-provider
                #default="{ errors }"
                name="User Name"
                :rules="{required: true, regex: /^[a-zA-Z0-9\s]*$/ }"
              >
                <b-form-input
                  v-model="editUser.name"
                  placeholder="Enter User Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                :rules="{ required: true, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ }"
              >
                <b-form-input
                  v-model="editUser.email"
                  type="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Email Id"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Password">
              <validation-provider
                #default="{ errors }"
                name="Password"
              >
                <b-form-input
                  v-model="editUser.pass"
                  placeholder="Enter Password"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Role">
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  value-field="value"
                  text-field="label"
                  v-model="editUser.role"
                  :options="roleType"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Role</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Select permissions below">
              <div
                v-for="option in permissions"
                :key="option.value"
              >
                <b-form-checkbox
                  :value="option.value"
                  v-model="editUser.permissions.menu"
                >
                  <strong>{{ option.text }}</strong>
                </b-form-checkbox>

                <b-form-checkbox-group
                  v-model="editUser.permissions.submenu"
                  :disabled="!editUser.permissions.menu.includes(option.value)"
                >
                  <b-form-checkbox
                    v-for="subOption in option.sub"
                    :key="subOption.value"
                    :value="subOption.value"
                  >
                    {{ subOption.text }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <hr />

              </div>
            </b-form-group>

            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelEdit"
              >Cancel</b-button>
              <b-overlay
                :show="loadbtn"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="!loadbtn"
              >
                <b-button
                  pill
                  type="submit"
                  variant="primary"
                  @click.prevent="updateUser"
                >Update</b-button>
              </b-overlay>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { userList, userAdd, userUpdate, userStatus } from '@/api/user'
import constantData from '@/helpers/constant'
export default {
  data () {
    return {
      perPage: 20,
      loadbtn: false,
      currentPage: 1,
      editModal: false,
      addModal: false,
      tabledata: [],
      loading: true,
      total: 0,
      filter: {
        role: null,
        name: null,
        status: null,
        p: 1,
        size: 20
      },
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'role', label: 'Role' },
        { key: 'active', label: 'Status' },
        { key: 'add_time', label: 'Join Date' },
        'action'
      ],
      roleType: constantData.roleType,
      statusList: constantData.statusList,
      editUser: {
        permissions: {
          menu: [],
          submenu: []
        }
      },
      addUser: {
        permissions: {
          menu: [],
          submenu: []
        },
        pass: null,
        name: null,
        email: null,
        rights: null,
        role: null
      },
      permissions: [
        {
          text: 'Influencer',
          value: 2000,
          sub: [
            {
              text: 'List',
              value: 2001
            },
            {
              text: 'YouTube',
              value: 2002
            },
            {
              text: 'Instagram',
              value: 2003
            },
            {
              text: 'Mediator',
              value: 2004
            },
            {
              text: 'Agency',
              value: 2005
            }
          ]
        },
        {
          text: 'Brand',
          value: 3000,
          sub: [
            {
              text: 'List',
              value: 3001
            }
          ]
        },
        {
          text: 'Campaign',
          value: 4000,
          sub: [
            {
              text: 'List',
              value: 4001
            }
          ]
        },
        {
          text: 'System Console',
          value: 5000
        }
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    resetFilter () {
      this.filter = {
        role: null,
        name: null,
        status: null,
        p: 1,
        size: 20
      }
      this.getData()
    },
    resetUser () {
      this.addUser = {
        pass: null,
        name: null,
        email: null,
        rights: null,
        role: null,
        permissions: {
          menu: [],
          submenu: []
        }
      }
    },
    addmodal () {
      this.$refs.addModal.show()
    },
    editmodal (item) {
      const rights = JSON.parse(item.rights)
      console.log(rights)
      this.editUser = {
        name: item.name,
        email: item.email,
        role: item.role,
        user_id: item.user_id,
        permissions: {
          menu: (rights && rights.menu) ? rights.menu : [],
          submenu: (rights && rights.submenu) ? rights.submenu : []
        }
      }
      this.$refs.editModal.show()
    },
    cancelAdd () {
      this.resetUser()
      this.$refs.addModal.hide()
    },
    cancelEdit () {
      this.$refs.editModal.hide()
    },
    search () {
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    userStatusChange (item) {
      try {
        this.$bvModal.msgBoxConfirm(item.status ? 'Are are you sure want to active this user?' : 'Are are you sure want to deactive this user?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            user_id: item.user_id,
            status: item.status ? 1 : 0
          }
          if (value) {
            const response = await userStatus(data)
            if (response.succ) {
              this.$bvToast.toast(item.status ? 'User activated successfully' : 'User deactivated successfully', {
                title: 'Active Status',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Deactive status error!',
                variant: 'danger',
                solid: true
              })
            }
          } else {
            item.status = !item.status
          }
        }).catch((error) => {
          console.log('cancel status error.', error)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getData () {
      this.loading = true
      const response = await userList(this.filter)
      if (response.succ) {
        this.total = response.totaluser
        this.tabledata = response.data.map(item => {
          return {
            name: item.name,
            email: item.email,
            status: !!item.status,
            role: item.role,
            user_id: item.user_id,
            rights: item.rights,
            add_time: item.add_time
          }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async updateUser () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this user?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                email: this.editUser.email,
                name: this.editUser.name,
                role: this.editUser.role,
                pass: this.editUser.pass,
                rights: JSON.stringify(this.editUser.permissions),
                user_id: this.editUser.user_id
              }
              if (value) {
                this.loadbtn = true
                const response = await userUpdate(data)
                if (response.succ) {
                  this.$bvToast.toast('User updated successfully', {
                    title: 'Updated',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.$refs.editModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
                this.loadbtn = false
              }
            }).catch(() => {
              console.log('cancel update.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async adduser () {
      this.$refs.addchannelrules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to add this user?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                email: this.addUser.email,
                name: this.addUser.name,
                role: this.addUser.role,
                pass: this.addUser.pass,
                rights: JSON.stringify(this.addUser.permissions)
              }
              if (value) {
                this.loadbtn = true
                const response = await userAdd(data)
                if (response.succ) {
                  this.$bvToast.toast('User added successfully', {
                    title: 'Added User',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.resetUser()
                  this.$refs.addModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Add User error!',
                    variant: 'danger',
                    solid: true
                  })
                }
                this.loadbtn = false
              }
            }).catch(() => {
              console.log('cancel add.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  }
}
</script>

<style datad>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
